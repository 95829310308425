.signinFormWrapper {
    border: 1px solid #ebedf0;
    border-radius: 12px;
    padding: 58px 10%;
    width: 50vh;
    max-width: 400px;
    box-shadow: 0 10px 25px 0 rgba(41, 52, 73, 0.5);
    // margin-right: -433px;
    // margin-left: 183px;
    // justify-content: flex-end !important;
}