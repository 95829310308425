.calendarContainer {
    margin-top: 15px;
}

/* client/src/feature/Cabinet/Home/container/Home.css */
.home-container {
    transition: filter 0.3s ease;
}

.home-container.blurred {
    filter: blur(5px);
    pointer-events: none;
    /* Prevent interactions */
}
.slick-slider {
    // background-color: black;
    border-radius: 8px;
    padding: 10px;
  }

  .slick-dots li button:before {
    color: white;
  }

  .slick-prev, .slick-next {
    color: white;
    z-index: 1;
  }
