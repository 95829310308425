.signupFormWrapper {
    // background-color: #ffffff;
    border: 1px solid #ebedf0;
    padding: 15px 20%;
    position: relative;
    // left: 50%;
    // transform: translate(-50%, 30%);
    border-radius: 12px;

    // box-shadow: 0 10px 25px 0 rgba(41, 52, 73, 0.5);
}

.authHeader {
    text-align: center;
}