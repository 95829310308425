.notFoundWrapper {
    text-align: center;

    h1 {
        font-size: 124px;
        font-weight: 600;
        color: rgba(0,0,0,.84);
        margin-bottom: 0;
    }

    h2 {
        font-size: 44px;
        color: rgba(0,0,0,.3);
        fill: rgba(0,0,0,.3);
    }
}