.usersList {
    text-align: center;
    margin: 25px 0;
}

.employeeReport{
    width: 1200px;
    width: 400px;
    padding: 20px;
    display: none;
}
