@import '~antd/dist/antd.css';

.ant-col-xs-offset-5,
.ant-col-sm-12,
.ant-col-sm-offset-6,
.ant-col-md-10,
.ant-col-md-offset-7,
.ant-col-lg-8,
.ant-col-lg-offset-8,
.ant-col-xl-6,
.ant-col-xl-offset-9 {
    display: block !important;
    margin: 0 !important;
    width: 100%;

}


.ant-menu-submenu-arrow {
    display: none;
}


.ant-menu-item:hover {
    // background: #494848 !important;
    // // width: 40px !important;

    // margin: 15px !important;
    display: block !important;
    background: transparent !important;
}

.ant-menu-item-selected {
    background: #494848 !important;
    // width: 40px !important;
    // margin: 15px !important;
    // display: flex !important;
    // justify-content: center !important;
    // align-items: center !important;
    // text-align: center !important;

}

.submenu-item,
.submenu-item:hover,

.ant-menu-sub,
.ant-menu-sub:hover {
    // background-color: #494848 !important;
    margin: 0 !important;
    // color: white !important;
    color: black !important;
    background-color: #ffffff !important;

}

/* Assuming you have a class for the Select component */
.ant-select-focused .ant-select-selector {
    border-color: #40a9ff;
    /* Change border color when focused */
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    /* Add shadow for focus effect */
}

/* Optional: Style the placeholder when focused */
.ant-select-focused .ant-select-selection-item {
    color: rgba(0, 0, 0, 0.85);
    /* Change placeholder color when focused */
}

.calendarContainer {
    margin-top: 15px;
}

/* client/src/feature/Cabinet/Home/container/Home.css */
.home-container {
    transition: filter 0.3s ease;
}

.home-container.blurred {
    filter: blur(5px);
    pointer-events: none;
    /* Prevent interactions */
}